export class CustomerPresentationInput {

  constructor(
    public readonly company: string,
    public readonly email: string,
    public readonly phoneNumber: string,
    public readonly website: string | null,
    public readonly streetAddress: string,
    public readonly city: string,
    public readonly state: string,
    public readonly postalCode: string,
    public readonly countryId: string,
    public readonly countryName: string,
    public readonly countryCode: string,
    public readonly id: string|null,
    public readonly defaultPaymentMethodId?: string | null,
  ) {
  }
}

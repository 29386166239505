import { CountryPresentation } from '@/domain/statics/presentation/CountryPresentation'
import type { IBillingDetailsPresentation } from '@/domain/stripe/customer/presentation/contract/IBillingDetailsPresentation'

export class BillingDetailsPresentation implements IBillingDetailsPresentation {
  constructor(
    public readonly company: string = '',
    public readonly email: string = '',
    public readonly contactNumber: string = '',
    public readonly companyWebsite: string | null = null,
    public readonly address: string = '',
    public readonly city: string = '',
    public readonly stateOrProvince: string = '',
    public readonly zipOrPostalCode: string = '',
    public readonly country: CountryPresentation | undefined = undefined,
    public readonly id: string | null = null,
    public readonly defaultPaymentMethodId: string | null = null,
  ) {
  }

  public get registeredInfoAvailable(): boolean {
    return true
  }
}

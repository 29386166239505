import { CustomerPaymentMethodsPresentation } from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/CustomerPaymentMethodsPresentation'
import { PaymentMethodPresentation } from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/PaymentMethodPresentation'
import { SetupIntentPresentation } from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/SetupIntentPresentation'
import { InvoicePresentation } from '@/domain/stripe/invoice/presentation/InvoicePresentation'
import { trackActionExecutionProgress } from '@/infrastructure/store/ActionLoadingIndicator'
import { ICheckoutButtonStore } from '@/infrastructure/store/modules/contract/shop/ICheckoutButton.store'
import { IPaymentDetailsStore } from '@/infrastructure/store/modules/contract/shop/IPaymentDetails.store'
import { IPaymentMethodStore } from '@/infrastructure/store/modules/contract/shop/IPaymentMethod.store'
import { HandledAction } from '@/infrastructure/utils/extensions/vuex'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'ShopPaymentDetails',
  namespaced: true,
})
export default class PaymentDetailsStore
  extends VuexModule
  implements IPaymentDetailsStore, IPaymentMethodStore, ICheckoutButtonStore {

  private _defaultPaymentMethod?: PaymentMethodPresentation

  public get defaultPaymentMethod(): PaymentMethodPresentation | undefined {
    return this._defaultPaymentMethod
  }

  private _customerId = ''

  public get customerId(): string {
    return this._customerId
  }

  private _registeredCardPaymentMethod: PaymentMethodPresentation | undefined

  public get registeredCardPaymentMethod(): PaymentMethodPresentation | undefined {
    return this._registeredCardPaymentMethod
  }

  private _invoice: InvoicePresentation = {} as InvoicePresentation

  public get invoice(): InvoicePresentation {
    return this._invoice
  }

  private _setupIntent: SetupIntentPresentation = {} as SetupIntentPresentation

  public get setupIntent(): SetupIntentPresentation {
    return this._setupIntent
  }

  private _hasValidCreditCardInformation = false

  public get hasValidCreditCardInformation(): boolean {
    return this._hasValidCreditCardInformation
  }

  private _useRegisteredInfo = false

  public get useRegisteredInfo(): boolean {
    return this._useRegisteredInfo
  }

  private _registeredInfoAvailable = false

  public get registeredInfoAvailable(): boolean {
    return this._registeredInfoAvailable
  }

  private _convertingQuote = false

  public get convertingQuote(): boolean {
    return this._convertingQuote
  }

  private _processingPayment = false

  public get processingPayment(): boolean {
    return this._processingPayment
  }

  private _hasAcceptedTheTermsOfService = true

  public get hasAcceptedTheTermsOfService(): boolean {
    return this._hasAcceptedTheTermsOfService
  }

  private _showCreditCardElementInEditionDialogMode = false

  public get showCreditCardElementInEditionDialogMode(): boolean {
    return this._showCreditCardElementInEditionDialogMode
  }

  private _showCreditCardElementInAdditionDialogMode = false

  public get showCreditCardElementInAdditionDialogMode(): boolean {
    return this._showCreditCardElementInAdditionDialogMode
  }

  private _creatingSetupIntent = false

  public get creatingSetupIntent(): boolean {
    return this._creatingSetupIntent
  }

  private _isLoadingPaymentMethod = true

  public get isLoadingPaymentMethod(): boolean {
    return this._isLoadingPaymentMethod
  }

  public get isProcessPaymentButtonDisabled(): boolean {
    return (
      !this.hasValidCreditCardInformation
      || this.convertingQuote
      || this.processingPayment
      || !this.hasAcceptedTheTermsOfService
    )
  }

  public get isCheckoutButtonDisabled(): boolean {
    return (
      this.convertingQuote
      || !this.hasAcceptedTheTermsOfService
    )
  }

  @Mutation
  public updateInvoice(value?: InvoicePresentation): void {
    if (value) {
      this._invoice = value
    }
  }

  @Mutation
  public updateSetupIntent(value: SetupIntentPresentation): void {
    this._setupIntent = value
  }

  @Mutation
  public updateHasValidCreditCardInformation(value: boolean): void {
    this._hasValidCreditCardInformation = value
  }

  @Mutation
  public resetStore(): void {
    this._hasValidCreditCardInformation = false
    this._hasAcceptedTheTermsOfService = true
    this._useRegisteredInfo = false
    this._registeredInfoAvailable = false
    this._invoice = {} as InvoicePresentation
    this._convertingQuote = false
    this._processingPayment = false
  }

  @Mutation
  public updateUseRegisteredInfo(available: boolean): void {
    this._useRegisteredInfo = available
  }

  @Mutation
  public updateRegisteredCardPaymentMethod(cardPaymentMethod: PaymentMethodPresentation): void {
    this._registeredCardPaymentMethod = cardPaymentMethod
  }

  @Mutation
  public updateConvertingQuoteStatus(value: boolean) {
    this._convertingQuote = value
  }

  @Mutation
  public updateProcessingPaymentStatus(value: boolean) {
    this._processingPayment = value
  }

  @Mutation
  public updateHasAcceptedTheTermsOfService(value: boolean): void {
    this._hasAcceptedTheTermsOfService = value
  }

  @Mutation
  public updateCustomerPaymentMethods(presentation: CustomerPaymentMethodsPresentation): void {
    this._useRegisteredInfo = presentation.registeredInfoAvailable
    this._registeredInfoAvailable = presentation.registeredInfoAvailable
    this._customerId = presentation.customerId
    const [registeredCardPaymentMethod] = presentation.paymentMethods
    this._registeredCardPaymentMethod = registeredCardPaymentMethod
  }

  @Mutation
  public updateDefaultPaymentMethod(paymentMethod: PaymentMethodPresentation): void {
    this._defaultPaymentMethod = paymentMethod
  }

  @Mutation
  public updateShowCreditCardElementInEditionDialogMode(value: boolean): void {
    this._showCreditCardElementInEditionDialogMode = value
  }

  @Mutation
  public updateShowCreditCardElementInAdditionDialogMode(value: boolean): void {
    this._showCreditCardElementInAdditionDialogMode = value
  }

  @Mutation
  public updateCreatingSetupIntentStatus(value: boolean): void {
    this._creatingSetupIntent = value
  }

  @Mutation
  public updateIsLoadingPaymentMethod(value: boolean): void {
    this._isLoadingPaymentMethod = value
  }

  @HandledAction({ commit: 'updateInvoice' })
  public convertQuote<T = InvoicePresentation>(callback: () => Promise<T | void>): Promise<T | void> {
    return trackActionExecutionProgress(callback, this.updateConvertingQuoteStatus)
  }

  @HandledAction({ commit: 'updateSetupIntent' })
  public createSetupIntent(
    callback: () => Promise<SetupIntentPresentation | void>,
  ): Promise<SetupIntentPresentation | void> {
    return trackActionExecutionProgress(callback, this.updateCreatingSetupIntentStatus)
  }

  @HandledAction({ commit: 'updateCustomerPaymentMethods' })
  public fetchData(
    action: () => Promise<CustomerPaymentMethodsPresentation | void>,
  ): Promise<CustomerPaymentMethodsPresentation | void> {
    return trackActionExecutionProgress(action, this.updateIsLoadingPaymentMethod)
  }
}

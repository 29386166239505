import { STORE_ADMIN_PERMISSIONS } from '@/shared/config/variables'

export function startDownload(link: string, window: Window) {
  const anchor = window.document.createElement('a')
  anchor.href = link
  window.document.body.appendChild(anchor)
  anchor.click()
  window.document.body.removeChild(anchor)
  // @ts-ignore type was not defined but exists
  window.URL?.revokeObjectURL?.call(window.URL, anchor.href)
}

type AvailableAccountPath = 'login'
  | 'sign-up'
  | 'reset-password'
  | 'change-password'
  | 'finalize-registration'
  | 'email-confirmation'
  | 'email-sent-successfully'
  | 'email-confirmed'
  | 'logout'

export function redirectToLP360Account(path: AvailableAccountPath): void {
  const url = new URL(path, process.env.VUE_APP_LP360_ACCOUNT_URL as string)
  url.searchParams.append('redirect', window.location.href)

  STORE_ADMIN_PERMISSIONS.forEach(permission => {
    url.searchParams.append('permissions', permission)
  })

  window.location.href = url.toString()
}

export function redirectToLP360Cloud(): void {
  const url = new URL(process.env.VUE_APP_LP360_CLOUD_URL as string)

  window.open(url.toString(), '_blank')
}

export function formatToUTCDate(date: Date): Date {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}

export function redirectToCheckout(cartId?: string, subscriptionId?: string): void {
  const url = new URL(process.env.VUE_APP_LP360_STORE_CHECKOUT_URL as string)

  if (cartId) {
    url.searchParams.append('cart', cartId)
  } else if (subscriptionId) {
    url.searchParams.append('subscription', subscriptionId)
  }

  window.location.href = url.toString()
}

export class UsBankAccountPresentation {
  constructor(
    public readonly fingerprint: string,
    public readonly bankName: string,
    public readonly routingNumber: string,
    public readonly last4Digits: string,
    public readonly accountHolderType: string,
  ) {
  }
}

import { RouteConfig } from 'vue-router'

const licenses: RouteConfig[] = [
  {
    path: '',
    name: 'shop.licenses.home',
    component: () => import('@/infrastructure/views/shop/license/LicensesView.vue'),
  },
  {
    path: 'summary',
    name: 'shop.licenses.summary',
    component: () => import('@/infrastructure/views/shop/license/SummaryView.vue'),
    meta: {
      title: 'shop.summary.title',
      breadcrumb: 'shop.summary.title',
    },
  },
]

const points: RouteConfig[] = [
  {
    path: '',
    name: 'shop.points.home',
    component: () => import('@/infrastructure/views/shop/point/PointsView.vue'),
  },
]

export const routes: RouteConfig[] = [
  {
    path: '/shop',
    name: 'shop.home',
    redirect: { path: '/shop/licenses' },
    component: () => import('@/infrastructure/views/shop/IndexView.vue'),
    meta: {
      title: 'shop.title',
      breadcrumb: 'shop.title',
    },
    children: [
      {
        path: 'licenses',
        component: () => import('@/infrastructure/views/shop/license/IndexView.vue'),
        children: licenses,
        meta: {
          title: 'shop.title',
          breadcrumb: 'shop.licenses.title',
        },
      },
      {
        path: 'points',
        component: () => import('@/infrastructure/views/shop/point/IndexView.vue'),
        children: points,
        meta: {
          title: 'shop.title',
          breadcrumb: 'shop.points.title',
        },
      },
    ],
  },
]

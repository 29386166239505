import { UserProfilePresentation } from '@/domain/authenticating/authentication/presentation/UserProfilePresentation'
import { ssoCookieName } from '@/domain/authenticating/authorization/repository/AuthorizationRepository'
import { RefreshTokenUseCase } from '@/domain/authenticating/authorization/useCase/RefreshTokenUseCase'
import { AuthenticatingTokens } from '@/domain/DITokens'
import { IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { HttpError } from '@/infrastructure/api/http/HttpError'
import { StatusCode } from '@/infrastructure/api/http/StatusCode'
import { getSsoCookieOrThrow } from '@/infrastructure/utils/extensions/js-cookie'
import { AxiosResponse } from 'axios'
import { inject, injectable } from 'inversify'
import Cookies from 'js-cookie'

export type SsoCookie = {
  _token: string
  _refreshToken: string
  _userProfile: UserProfilePresentation
}

@injectable()
export class RetryRefreshToken {
  protected pendingRequest: Promise<void> | null = null

  constructor(
    @inject(AuthenticatingTokens.AuthorizationTokens.useCase.RefreshTokenUseCase)
    private readonly refreshTokenUseCase: RefreshTokenUseCase,
  ) {
  }

  public async onResponseFailed(error: HttpError, httpClient: IHttpClient): Promise<AxiosResponse> {
    if (error.code !== StatusCode.Unauthorized && error.message.toUpperCase() !== 'MALFORMED_AUTHORIZATION_HEADER'|| !error.response) {
      return Promise.reject(error)
    }

    if (!this.pendingRequest) {
      this.pendingRequest = this.handleCookies().finally(() => {
        // In case of multiple unauthorized requests, we need to ensure each of those retry use the same promise to resolve their failures
        // So this ensures the refresh token request is call only once for multiple unauthorized requests
        // This is safe because there is not way that we are going to receive so many unauthorized requests that won't be resolved in 5 seconds
        // If that happens, the pendingRequest will be null and the next request will call the refreshTokenUseCase again
        setTimeout(() => {
          this.pendingRequest = null
        }, 5000)
      })
    }
    await this.pendingRequest

    return httpClient.doRequest(error.response.config)
  }

  private async handleCookies(): Promise<void> {
    const currentCookie = getSsoCookieOrThrow()

    const { token, refresh_token: refreshToken } = await this.refreshTokenUseCase.execute(currentCookie._refreshToken)

    const updatedCookie = {
      ...currentCookie,
      _token: token,
      _refreshToken: refreshToken,
    }

    const COOKIE_DURATION_DAYS = 30

    Cookies.set(ssoCookieName, JSON.stringify(updatedCookie), {
      expires: COOKIE_DURATION_DAYS,
      domain: process.env.VUE_APP_LP360_APP_DOMAIN,
    })
  }
}

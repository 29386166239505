import { Card } from '@/domain/stripe/customer/sub-domains/paymentMethod/model/Card'
import { UsBankAccount } from '@/domain/stripe/customer/sub-domains/paymentMethod/model/UsBankAccount'

export class PaymentMethod {
  constructor(
    public readonly id: string,
    public readonly card?: Card | null,
    public readonly usBankAccount?: UsBankAccount | null,
  ) {
  }
}

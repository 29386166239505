import { ApiTokens } from '@/infrastructure/api/DITokens'
import { HttpClient, IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { middlewareContainer } from '@/infrastructure/api/http/middleware/DIContainer'
import { getSsoCookieOrThrow } from '@/infrastructure/utils/extensions/js-cookie'
import { Container } from 'inversify'
import { MiddlewareTokens } from '@/infrastructure/api/http/middleware/DITokens'
import { RetryRefreshToken } from '@/infrastructure/api/http/middleware/RetryRefreshToken'

export function apiContainer(container: Container): Container {

  middlewareContainer(container)
  container
    .bind<IHttpClient>(ApiTokens.httpClient)
    .toConstantValue(new HttpClient())

  const authorizedHttpClient = new HttpClient(() => getSsoCookieOrThrow()._token)
  authorizedHttpClient.addInterceptor({
    onResponse: {
      failed: (error) => container.get<RetryRefreshToken>(MiddlewareTokens.RetryRefreshToken).onResponseFailed(error, authorizedHttpClient),
    },
  })

  container
    .bind<IHttpClient>(ApiTokens.authenticatedHttpClient)
    .toConstantValue(authorizedHttpClient)

  return container
}

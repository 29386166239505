import { CardPresentation } from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/CardPresentation'
import { UsBankAccountPresentation } from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/UsBankAccountPresentation'

export class PaymentMethodPresentation {

  constructor(
    public readonly id: string,
    public readonly card?: CardPresentation | null,
    public readonly usBankAccount?: UsBankAccountPresentation | null,
  ) {
  }
}

import DITokens from '@/domain/stripe/customer/DITokens'
import type { IBillingDetailsPresentation } from '@/domain/stripe/customer/presentation/contract/IBillingDetailsPresentation'
import { CustomerPresentationInput } from '@/domain/stripe/customer/presenter/contract/CustomerPresentationInput'
import type { IBillingDetailsPresenter } from '@/domain/stripe/customer/presenter/contract/IBillingDetailsPresenter'
import { CustomerException } from '@/domain/stripe/customer/repository/contract/exception/CustomerException'
import type { ICustomerRepository } from '@/domain/stripe/customer/repository/contract/ICustomerRepository'
import { inject, injectable } from 'inversify'

@injectable()
export class GetBillingDetailsUseCase {
  constructor(
    @inject(DITokens.repository.ICustomerRepository)
    private readonly repository: ICustomerRepository,
    @inject(DITokens.presenter.IBillingDetailsPresenter)
    private readonly presenter: IBillingDetailsPresenter,
  ) {
  }

  async execute(): Promise<IBillingDetailsPresentation> {
    let customerPresentationInput: CustomerPresentationInput | CustomerException

    try {
      const customer = await this.repository.load()

      customerPresentationInput = new CustomerPresentationInput(
        customer.company,
        customer.email,
        customer.phoneNumber,
        customer.website,
        customer.streetAddress,
        customer.city,
        customer.state,
        customer.postalCode,
        customer.countryId,
        customer.countryName,
        customer.countryCode,
        customer.id,
        customer.defaultPaymentMethodId,
      )
    } catch (e) {
      if (e instanceof CustomerException) {
        customerPresentationInput = e
      } else {
        throw e
      }
    }

    return this.presenter.present(customerPresentationInput)
  }
}

import type { IUsBankAccountResponseDto } from '@/domain/stripe/customer/sub-domains/paymentMethod/client/dto/Dtos'
import { injectable } from 'inversify'
import { UsBankAccount } from '@/domain/stripe/customer/sub-domains/paymentMethod/model/UsBankAccount'
import { IUsBankAccountMapper } from '@/domain/stripe/customer/sub-domains/paymentMethod/mapper/contract/IUsBankAccountMapper'

@injectable()
export class UsBankAccountMapper implements IUsBankAccountMapper {
  public toModel(usBankAccount: IUsBankAccountResponseDto): UsBankAccount {
    return new UsBankAccount(
      usBankAccount.fingerprint,
      usBankAccount.bank_name,
      usBankAccount.routing_number,
      usBankAccount.last_4_digits,
      usBankAccount.account_holder_type,
    )
  }
}

const apiClient = {
  IPaymentMethodApiClient: Symbol(),
}

const repository = {
  IPaymentMethodRepository: Symbol(),
}

const mapper = {
  IPaymentMethodMapper: Symbol(),
  IPaymentMethodsMapper: Symbol(),
  ICardMapper: Symbol(),
  ISetupIntentMapper: Symbol(),
  IUsBankAccountMapper: Symbol(),
}

const presenter = {
  ICardPresenter: Symbol(),
  IUsBankAccountPresenter: Symbol(),
  IPaymentMethodPresenter: Symbol(),
  ICustomerPaymentMethodsPresenter: Symbol(),
  ISetupIntentPresenter: Symbol(),
}

const useCase = {
  GetPaymentMethodUseCase: Symbol(),
  CreateSetupIntentUseCase: Symbol(),
}

export default {
  apiClient,
  repository,
  mapper,
  presenter,
  useCase,
}

import { StripeTokens } from '@/domain/DITokens'
import { PaymentMethod } from '@/domain/stripe/customer/sub-domains/paymentMethod/model/PaymentMethod'
import { PaymentMethodPresentation } from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/PaymentMethodPresentation'
import type { ICardPresenter } from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/contract/ICardPresenter'
import type {
  IPaymentMethodPresenter,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/contract/IPaymentMethodPresenter'
import { inject, injectable } from 'inversify'
import { IUsBankAccountPresenter } from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/contract/IUsBankAccountPresenter'

@injectable()
export class PaymentMethodPresenter implements IPaymentMethodPresenter {
  constructor(
    @inject(StripeTokens.PaymentTokens.presenter.ICardPresenter)
    private readonly card: ICardPresenter,
    @inject(StripeTokens.PaymentTokens.presenter.IUsBankAccountPresenter)
    private readonly usBankAccountPresenter: IUsBankAccountPresenter,
  ) {
  }

  public present(input: PaymentMethod): PaymentMethodPresentation {
    return new PaymentMethodPresentation(
      input.id,
      input.card ? this.card.present(input.card) : null,
      input.usBankAccount ? this.usBankAccountPresenter.present(input.usBankAccount) : null,
    )
  }
}

import type { ICustomerDto } from '@/domain/stripe/customer/client/dto/Dtos'
import type { ICustomerMapper } from '@/domain/stripe/customer/mapper/contract/ICustomerMapper'
import { Customer } from '@/domain/stripe/customer/model/Customer'
import { injectable } from 'inversify'
import parseInt from 'lodash/parseInt'

@injectable()
export class CustomerMapper implements ICustomerMapper {
  public toModel(dto: ICustomerDto): Customer {
    return new Customer(
      dto.name,
      dto.email,
      dto.phone_number,
      dto.website,
      dto.address.street_address,
      dto.address.city,
      dto.address.state,
      dto.address.postal_code,
      dto.address.country.id + '',
      dto.address.country.name,
      dto.address.country.code,
      dto.id,
      dto.invoice_settings?.default_payment_method,
    )
  }

  public toDto(model: Customer): ICustomerDto {
    return {
      id: model.id,
      name: model.company,
      email: model.email,
      phone_number: model.phoneNumber,
      website: model.website,
      address: {
        street_address: model.streetAddress,
        city: model.city,
        state: model.state,
        postal_code: model.postalCode,
        country: {
          id: parseInt(model.countryId),
          code: model.countryCode,
          name: model.countryName,
        },
      },
    }
  }

}

import { injectable } from 'inversify'
import { UsBankAccount } from '@/domain/stripe/customer/sub-domains/paymentMethod/model/UsBankAccount'
import { IUsBankAccountPresenter } from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/contract/IUsBankAccountPresenter'
import { UsBankAccountPresentation } from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/UsBankAccountPresentation'

@injectable()
export class UsBankAccountPresenter implements IUsBankAccountPresenter {
  public present(usBankAccount: UsBankAccount): UsBankAccountPresentation {
    return new UsBankAccountPresentation(
      usBankAccount.fingerprint,
      usBankAccount.bankName,
      usBankAccount.routingNumber,
      usBankAccount.last4Digits,
      usBankAccount.accountHolderType,
    )
  }
}

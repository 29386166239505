import { RouteConfig } from 'vue-router'

export const routes: RouteConfig[] = [
  {
    path: '/billing',
    name: 'billing.home',
    component: () => import('@/infrastructure/views/billing/IndexView.vue'),
    redirect: { name: 'billing.overview' },
    meta: {
      title: 'billing.title',
      breadcrumb: 'billing.title',
    },
    children: [
      {
        path: 'overview',
        name: 'billing.overview',
        component: () => import('@/infrastructure/views/billing/OverviewView.vue'),
        meta: {
          title: 'billing.title',
          breadcrumb: 'billing.overview.title',
        },
      },
      {
        path: 'history',
        name: 'billing.history',
        component: () => import('@/infrastructure/views/billing/HistoryView.vue'),
        meta: {
          title: 'billing.history.title',
          breadcrumb: 'billing.history.title',
        },
      },
    ],
  },
]

import { Coupon } from '@/domain/stripe/couponCode/model/Coupon'
import { Customer } from '@/domain/stripe/customer/model/Customer'
import { InvoiceLine } from '@/domain/stripe/invoice/model/InvoiceLine'
import { Link } from '@/domain/stripe/invoice/model/Link'
import { Payment } from '@/domain/stripe/invoice/model/Payment'
import { TotalAmount } from '@/domain/stripe/invoice/model/TotalAmount'
import { StripeModel } from '@/domain/stripe/shared/model/StripeModel'

export class Invoice extends StripeModel {
  constructor(
    public readonly id: string,
    public readonly number: string,
    public readonly customer: Customer|string,
    public readonly lines: InvoiceLine[],
    public readonly date: string,
    public readonly subtotal: TotalAmount,
    public readonly total: TotalAmount,
    public readonly payment: Payment,
    public readonly link: Link,
    public readonly coupon: Coupon|undefined,
    private _status: string,
    public readonly subscriptionId: string|null,
  ) {
    super()
  }

  public markPaid() {
    this.status = 'paid'
    this.isSynchronized = false
  }

  get status(): string {
    return this._status
  }

  set status(value: string) {
    this._status = value
  }

  public containsHardwareProduct(): boolean {
    return this.lines.some(line => line.productMetadata?.type === 'hardware')
  }
}

import { SharedTokens, StaticsTokens } from '@/domain/DITokens'
import type { ILocalizedStringProvider } from '@/domain/shared/contract/ILocalizedStringProvider'
import type { ICountryPresenter } from '@/domain/statics/presenter/contract/ICountryPresenter'
import { BillingDetailsPresentation } from '@/domain/stripe/customer/presentation/BillingDetailsPresentation'
import type { IBillingDetailsPresentation } from '@/domain/stripe/customer/presentation/contract/IBillingDetailsPresentation'
import { CouldNotGetOrSaveBillingDetailsPresentation } from '@/domain/stripe/customer/presentation/CouldNotGetOrSaveBillingDetailsPresentation'
import { NoRegisteredBillingDetailsPresentation } from '@/domain/stripe/customer/presentation/NoRegisteredBillingDetailsPresentation'
import { CustomerPresentationInput } from '@/domain/stripe/customer/presenter/contract/CustomerPresentationInput'
import type { IBillingDetailsPresenter } from '@/domain/stripe/customer/presenter/contract/IBillingDetailsPresenter'
import { CouldNotGetCustomerException } from '@/domain/stripe/customer/repository/contract/exception/CouldNotGetCustomerException'
import { CouldNotSaveCustomerException } from '@/domain/stripe/customer/repository/contract/exception/CouldNotSaveCustomerException'
import { CustomerEndpointsServerException } from '@/domain/stripe/customer/repository/contract/exception/CustomerEndpointsServerException'
import { CustomerException } from '@/domain/stripe/customer/repository/contract/exception/CustomerException'
import { CustomerNotFoundException } from '@/domain/stripe/customer/repository/contract/exception/CustomerNotFoundException'
import { inject, injectable } from 'inversify'

@injectable()
export class BillingDetailsPresenter implements IBillingDetailsPresenter {
  constructor(
    @inject(SharedTokens.ILocalizedStringProvider)
    private readonly localizedStringProvider: ILocalizedStringProvider,
    @inject(StaticsTokens.countryPresenter)
    private readonly countryPresenter: ICountryPresenter,
  ) {
  }

  private presentCustomerInfo(input: CustomerPresentationInput) {
    return new BillingDetailsPresentation(
      input.company,
      input.email,
      input.phoneNumber,
      input.website,
      input.streetAddress,
      input.city,
      input.state,
      input.postalCode,
      this.countryPresenter.present({
        id: input.countryId,
        name: input.countryName,
        code: input.countryCode,
      }),
      input.id,
      input.defaultPaymentMethodId,
    )
  }

  public present(
    input: CustomerPresentationInput | CustomerException,
  ): IBillingDetailsPresentation {
    if (input instanceof CustomerPresentationInput) {

      return this.presentCustomerInfo(input)
    } else {
      let message

      if (input instanceof CustomerNotFoundException) {
        message = this.localizedStringProvider.getText('shared.billingDetails.noRegisteredBillingDetails')
        return new NoRegisteredBillingDetailsPresentation(message)
      }

      if (input instanceof CouldNotSaveCustomerException) {
        message = this.localizedStringProvider.getText('shared.billingDetails.couldNotSaveBillingDetails')
      } else if (input instanceof CouldNotGetCustomerException) {
        message = this.localizedStringProvider.getText('shared.domainError.unknownError')
      } else if (input instanceof CustomerEndpointsServerException) {
        message = this.localizedStringProvider.getText('shared.domainError.serverError')
      } else {
        message = this.localizedStringProvider.getText('shared.domainError.unknownError')
      }
      return new CouldNotGetOrSaveBillingDetailsPresentation(message)
    }
  }
}

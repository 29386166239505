import { BillingDetailsPresentation } from '@/domain/stripe/customer/presentation/BillingDetailsPresentation'
import { IBillingDetailsSummaryStore } from '@/infrastructure/store/modules/contract/shop/IBillingDetailsSummary.store'
import { IPaymentMethodBillingDetailsStore } from '@/infrastructure/store/modules/contract/shop/IPaymentMethodBillingDetails.store'
import { HandledAction } from '@/infrastructure/utils/extensions/vuex'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'ShopBillingDetailsSummary',
  namespaced: true,
})
export default class BillingDetailsSummaryStore extends VuexModule implements IBillingDetailsSummaryStore, IPaymentMethodBillingDetailsStore {

  private _company = ''

  public get company(): string {
    return this._company
  }

  private _email = ''

  public get email(): string {
    return this._email
  }

  private _address = ''

  public get address(): string {
    return this._address
  }

  private _city = ''

  public get city(): string {
    return this._city
  }

  private _stateOrProvince = ''

  public get stateOrProvince(): string {
    return this._stateOrProvince
  }

  private _zipOrPostalCode = ''

  public get zipOrPostalCode(): string {
    return this._zipOrPostalCode
  }

  private _country: string | undefined = ''

  public get country(): string | undefined {
    return this._country
  }

  private _countryCode: string | undefined = ''

  public get countryCode(): string | undefined {
    return this._countryCode
  }

  private _defaultPaymentMethodId: string | null = null

  public get defaultPaymentMethodId(): string | null {
    return this._defaultPaymentMethodId
  }

  public get hasBillingDetails(): boolean {
    return !!(
      this.address
      || this.city
      || this.stateOrProvince
      || this.zipOrPostalCode
      || this.country
    )
  }

  @Mutation
  public resetState(): void {
    this._company = ''
    this._email = ''
    this._address = ''
    this._city = ''
    this._stateOrProvince = ''
    this._zipOrPostalCode = ''
    this._country = ''
    this._countryCode = ''
    this._defaultPaymentMethodId = null
  }

  @Mutation
  public updateBillingDetailsSummary(presentation: BillingDetailsPresentation): void {

    this._company = presentation.company
    this._email = presentation.email
    this._address = presentation.address
    this._city = presentation.city
    this._stateOrProvince = presentation.stateOrProvince
    this._zipOrPostalCode = presentation.zipOrPostalCode
    this._country = presentation.country?.name
    this._countryCode = presentation.country?.code
    this._defaultPaymentMethodId = presentation.defaultPaymentMethodId
  }

  @HandledAction({ commit: 'updateBillingDetailsSummary' })
  public async fetchData(
    action: () => Promise<BillingDetailsPresentation | void>,
  ): Promise<BillingDetailsPresentation | void> {

    return action()
  }

  @Mutation
  public updateDefaultPaymentMethodId(value: string): void {
    this._defaultPaymentMethodId = value
  }
}
